<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H4ZM18.4219 6L5.5781 6L12 11.6664L18.4219 6ZM4 7.2748V18H20V7.2748L13.3232 13.1661C12.5672 13.8332 11.4328 13.8332 10.6768 13.1661L4 7.2748Z"
      fill="#1F284D"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
