<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.834 6.16623L17.9111 6.24325C18.2614 6.59277 18.5635 6.89426 18.7601 7.22973C19.3094 8.16667 19.3094 9.32732 18.7601 10.2642C18.5634 10.5997 18.2613 10.9012 17.9111 11.2507L17.834 11.3277L10.2066 18.955L9.91369 19.2479H9.49948L5.7522 19.2479H4.7522L4.7522 18.2479L4.7522 14.5006V14.0864L5.0451 13.7935L12.6725 6.1662L12.7495 6.08908C13.0991 5.73884 13.4005 5.43674 13.736 5.24007C14.6729 4.6908 15.8336 4.69081 16.7705 5.24009C17.106 5.43676 17.4074 5.73886 17.757 6.0891L17.834 6.16623ZM17.0348 8.24123C16.9863 8.15847 16.8913 8.05199 16.4198 7.58044C15.9482 7.1089 15.8418 7.01397 15.759 6.96545C15.4467 6.78235 15.0598 6.78235 14.7475 6.96544C14.667 7.01263 14.5641 7.10371 14.1248 7.5424L16.4578 9.87544C16.8965 9.43613 16.9876 9.33322 17.0348 9.25273C17.2179 8.94042 17.2179 8.55354 17.0348 8.24123ZM15.0436 11.2897L12.7105 8.95659L6.7522 14.9148L6.7522 17.2479L9.08527 17.2479L15.0436 11.2897Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
