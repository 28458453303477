<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect :width="size" :height="size" rx="20" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.419 9.5183C15.174 9.25153 14 10.2006 14 11.4739V12.9893C12.8954 12.9893 12 13.8847 12 14.9893V28C12 29.1046 12.8954 30 14 30H20.507C18.9778 28.7625 18 26.8704 18 24.75C18 21.0221 21.0221 18 24.75 18C25.9282 18 27.0359 18.3019 28 18.8325V13.6166C28 12.6735 27.3412 11.8586 26.419 11.661L16.419 9.5183ZM23.0721 12.9893H16V11.4739L23.0721 12.9893Z"
      :fill="fill"
    />
    <path
      d="M27.7052 20.4101C26.8634 19.8358 25.8459 19.5 24.75 19.5C21.8505 19.5 19.5 21.8505 19.5 24.75C19.5 27.0925 21.0342 29.0767 23.1526 29.7526C23.1106 29.2444 22.4686 28.024 21.8017 26.7561C20.9075 25.0561 19.9683 23.2707 20.3699 23.0021L20.7357 22.976C21.7823 22.9012 22.5932 22.0304 22.5932 20.9811L27.7052 20.4101Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.75 30C27.6495 30 30 27.6495 30 24.75C30 21.8505 27.6495 19.5 24.75 19.5C21.8505 19.5 19.5 21.8505 19.5 24.75C19.5 27.6495 21.8505 30 24.75 30ZM25.4167 21.5V24.0833H28V25.4167H25.4167V28H24.0833V25.4167H21.5V24.0833H24.0833V21.5H25.4167Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, required: false, default: 40 },
  },
};
</script>
