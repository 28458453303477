<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect :width="size" :height="size" rx="20" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.7716 12C14.7574 12.0905 14.75 12.1833 14.75 12.2778V14.2222C14.75 15.204 15.5459 16 16.5278 16H23.4722C24.4541 16 25.25 15.204 25.25 14.2222V12.2778C25.25 12.1833 25.2426 12.0905 25.2284 12H26.2222C27.2041 12 28 12.7959 28 13.7778V28.2222C28 29.2041 27.2041 30 26.2222 30H13.7778C12.7959 30 12 29.2041 12 28.2222V13.7778C12 12.7959 12.7959 12 13.7778 12H14.7716ZM18.1962 16C18.723 16.3174 19.3402 16.5 20 16.5C20.6598 16.5 21.277 16.3174 21.8038 16H18.1962ZM15.8 19.3241C15.3858 19.3241 15.05 19.6598 15.05 20.0741C15.05 20.4883 15.3858 20.8241 15.8 20.8241H18.3C18.7142 20.8241 19.05 20.4883 19.05 20.0741C19.05 19.6598 18.7142 19.3241 18.3 19.3241H15.8ZM15.0501 24.5185C15.0501 24.1043 15.3858 23.7685 15.8001 23.7685H18.3001C18.7143 23.7685 19.0501 24.1043 19.0501 24.5185C19.0501 24.9327 18.7143 25.2685 18.3001 25.2685H15.8001C15.3858 25.2685 15.0501 24.9327 15.0501 24.5185ZM25.0989 19.0441C25.3483 18.7133 25.2823 18.243 24.9515 17.9937C24.6208 17.7444 24.1505 17.8104 23.9012 18.1411L22.5374 19.9502L22.032 19.4418C21.7399 19.148 21.2651 19.1466 20.9713 19.4386C20.6775 19.7307 20.6761 20.2055 20.9681 20.4993L21.9976 21.5349C22.3648 21.9043 22.9741 21.8627 23.2877 21.4468L25.0989 19.0441ZM24.9515 22.4382C25.2823 22.6875 25.3483 23.1578 25.0989 23.4885L23.2877 25.8912C22.9741 26.3072 22.3648 26.3488 21.9976 25.9794L20.9681 24.9438C20.6761 24.65 20.6775 24.1751 20.9713 23.8831C21.2651 23.5911 21.7399 23.5925 22.032 23.8863L22.5374 24.3947L23.9012 22.5856C24.1505 22.2548 24.6208 22.1888 24.9515 22.4382Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.2284 14.5C25.0951 15.3499 24.3596 16 23.4722 16H16.5278C15.6404 16 14.9049 15.3499 14.7716 14.5C14.7574 14.4095 14.75 14.3167 14.75 14.2222V12.2778C14.75 11.2959 15.5461 10.5 16.528 10.5H17.5505C18.1818 9.8814 19.0464 9.5 20 9.5C20.9536 9.5 21.8182 9.8814 22.4495 10.5H23.472C24.4538 10.5 25.25 11.2959 25.25 12.2778V14.2222C25.25 14.3167 25.2426 14.4095 25.2284 14.5ZM18.1962 16C18.723 16.3174 19.3402 16.5 20 16.5C20.6598 16.5 21.277 16.3174 21.8038 16H18.1962ZM21.837 12L21.3996 11.5713C21.0374 11.2164 20.5455 11 20 11C19.4545 11 18.9626 11.2164 18.6004 11.5713L18.163 12H16.528C16.3743 12 16.25 12.1245 16.25 12.2778V14.2222C16.25 14.3756 16.3744 14.5 16.5278 14.5H23.4722C23.6256 14.5 23.75 14.3756 23.75 14.2222V12.2778C23.75 12.1246 23.6257 12 23.472 12H21.837Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1D1E20' },
    size: { type: Number, required: false, default: 40 },
  },
};
</script>
