<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.7068 5.29282L15.0854 3.9142C15.8665 3.13316 17.1328 3.13316 17.9138 3.91421L20.0844 6.08481C20.8655 6.86586 20.8655 8.13219 20.0844 8.91324L18.7058 10.2919L13.7068 5.29282ZM12.2926 6.70702L17.2916 11.7061L8.99988 19.9978L4 19.9978L4 14.9995L12.2926 6.70702Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
  },
};
</script>
