<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.75 3.75C7.7835 3.75 7 4.5335 7 5.5C7 6.4665 7.7835 7.25 8.75 7.25C9.7165 7.25 10.5 6.4665 10.5 5.5C10.5 4.5335 9.7165 3.75 8.75 3.75Z"
      :fill="fill"
    />
    <path
      d="M15.25 3.75C14.2835 3.75 13.5 4.5335 13.5 5.5C13.5 6.4665 14.2835 7.25 15.25 7.25C16.2165 7.25 17 6.4665 17 5.5C17 4.5335 16.2165 3.75 15.25 3.75Z"
      :fill="fill"
    />
    <path
      d="M15.25 10.25C14.2835 10.25 13.5 11.0335 13.5 12C13.5 12.9665 14.2835 13.75 15.25 13.75C16.2165 13.75 17 12.9665 17 12C17 11.0335 16.2165 10.25 15.25 10.25Z"
      :fill="fill"
    />
    <path
      d="M13.5 18.5C13.5 17.5335 14.2835 16.75 15.25 16.75C16.2165 16.75 17 17.5335 17 18.5C17 19.4665 16.2165 20.25 15.25 20.25C14.2835 20.25 13.5 19.4665 13.5 18.5Z"
      :fill="fill"
    />
    <path
      d="M7 12C7 11.0335 7.7835 10.25 8.75 10.25C9.7165 10.25 10.5 11.0335 10.5 12C10.5 12.9665 9.7165 13.75 8.75 13.75C7.7835 13.75 7 12.9665 7 12Z"
      :fill="fill"
    />
    <path
      d="M8.75 16.75C7.7835 16.75 7 17.5335 7 18.5C7 19.4665 7.7835 20.25 8.75 20.25C9.7165 20.25 10.5 19.4665 10.5 18.5C10.5 17.5335 9.7165 16.75 8.75 16.75Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
